import './App.css';
import { BrowserRouter, Routes, Link, useLocation, Route } from "react-router-dom";
import { useEffect } from 'react';

import Homepage from './Pages/Homepage';
import Header from './Layouts/Header';
import { Register } from './Pages/Register';
import { CreatePost } from './Pages/CreatePost';
import { Sidebar } from './Layouts/Sidebar';
import Profile from './Pages/Profile';
import User from './Pages/User';
import PostDetail from './Pages/PostDetail';
import Kvkk from './Pages/Kvkk'

function App() {
  const withOutSidebarPages = ["create", "profile", "user"];

  // const openImage = () => {
  //   const galleryImages = document.querySelectorAll('.gallery-image');
  //   const popup = document.getElementById('popup');
  //   const popupImage = document.getElementById('popup-image');
  //   const closeBtn = document.getElementById('close');

  //   galleryImages.forEach(image => {
  //     image.addEventListener('click', () => {
  //       popup.style.display = 'block';
  //       popupImage.src = image.src;
  //     });
  //   });

  //   closeBtn.addEventListener('click', () => {
  //     popup.style.display = 'none';
  //   });
  // }

  const showSidebar = () => {
    if(!currentPath.includes('create') && !currentPath.includes('user') && !currentPath.includes('profile')){
      return (<Sidebar />)
    }
  }

  useEffect(() => {
    if (localStorage.theme == 'dark') {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  // openImage()
  },[])

  const currentPath = window.location.pathname;

  return (
    <div className='main min-h-screen h-screen overflow-hidden dark:bg-body-dark'>
    <Header />
    <div className='px-4 h-[90vh] overflow-hidden flex flex-col lg:flex-row items-start'>
      { showSidebar() }
      <div className='main mx-auto h-[90vh] overflow-auto w-full'>
      <BrowserRouter>
        <Routes>
          <Route key={58} path="/register" element={<Register />} />
          <Route key={57} path="/create" element={<CreatePost />} />
          <Route key={56} path="/profile" element={<Profile />} />
          <Route key={54} path="/user/:param" element={<User />} />
          <Route key={35} path="/ilan/:param" element={<PostDetail />} />
          <Route key={52} path="*" element={<Homepage />} />
          <Route key={15} path="kvkk" element={<Kvkk />} />
        </Routes>
      </BrowserRouter>
      </div>
      </div>
      <div className="popup flex flex-col justify-around" id="popup" onClick={(e) => { e.target.style.display = 'none' }} style={{ zIndex:"99999" }}>
        <span className="close" id="close">&times;</span>
        <img src="" alt="Büyük Resim" id="popup-image" loading='lazy'
          className="block w-full md:w-1/2 h-auto mt-20 mx-auto" style={{ maxHeight: "85vh"}} />
      </div>
      </div>
  );
}

export default App;
