// MAIN IMPORTS
import React, { useEffect, useState, useRef } from 'react'
import { setAdverts } from '../../Redux/Homepage'
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import axios from 'axios';
import { login } from '../../Validataions/Login';
import { active } from '../../Validataions/Active';
import { register } from '../../Validataions/Register';
import { useDispatch, useSelector } from 'react-redux';
import il_ilce from './data.json'
import { setMe, setFounded } from '../../Redux/MeSlice'
import { Alert }  from '../../Helpers/helpers';
import { RailwayAlertOutlined } from '@mui/icons-material';
import Select from 'react-select'

// MUI
import { Button } from '@mui/material';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

// COMPONENTS
import ModeSwitch from '../../Components/ModeSwitch';
import Popup from '../../Components/Popup';

// ICONS
import { GiHamburgerMenu } from "react-icons/gi";
import { CiBookmarkPlus, CiFilter } from "react-icons/ci";
import { BiSupport } from "react-icons/bi";

const Header = () => {
    const route = window.location.pathname;
    let hiddenFilter = route.includes('create') ? 'none' : 'flex';
    const Title = useRef();
    const Type  = useRef();
    const FeedbackRef  = useRef();
    const FeedSubmit = useRef();
    const [ feedSubmitDisabled, setFeedSubmitDisabled ] = useState(true);
    const [ loginModal, setLoginModal ] = useState(false);
    const [ registerModal, setRegisterModal ] = useState(false);
    const [ activeModal, setActiveModal ] = useState(false);
    const [ deleteModal, setDeleteModal ] = useState(false);
    const [ filterPopup, setFilterPopup ] = useState(false);
    const [ feedbackModal, setFeedbackModal ] = useState(false);
    const fontColor = localStorage.theme == 'dark' ? 'white': 'black'
    const mode = localStorage.theme ?? 'light';
    const [ilceler, setIlceler] = useState([]);
    const [isAward, setIsAward] = useState(false);
    const [il, setIl] = useState("Şehir");
    const [ilce, setIlce] = useState("İlçe");
    const dispatch = useDispatch();
    const { Me, Founded } = useSelector((store) => store.me)
    const header = {
        "Authorization" : "Bearer " + localStorage.token
      };
    // FORMS
    const activateForm = useFormik({
        initialValues: {
            code: '',
        },
        validationSchema: active,
        onSubmit: async values => {
            setActiveModal(false);
            let data = {
                "code" : values.code,
                "email" : localStorage.email
            }
            axios.post( process.env.REACT_APP_API_URL + "/activation", data )
            .then((response) => {
                let cevap = response.data;
                if(cevap.status == "success"){
                    localStorage.token = cevap.data
                    window.location.reload();
                }else{
                    Alert({type:"error", title:"Hata!", text:cevap.message
                   , cancelFunc: () => {setActiveModal(true)}, successFunc : () => {setActiveModal(true)}})
            }
            }).catch((error) => {
                Alert({type:"error", title:"Hata!", text:error.response.message
                , cancelFunc: () => {setActiveModal(true)}, successFunc : () => {setActiveModal(true)}})
            });
        },
        onChange: values => {
          
        }
    });
    const loginForm = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: login,
        onSubmit: async values => {
            setLoginModal(false)
            axios.post( process.env.REACT_APP_API_URL + "/login", values )
            .then((response) => {
                let cevap = response.data;
                if(cevap.status == "success"){
                    localStorage.token = cevap.data;
                    window.location.reload();
                }else{
                    Alert({type:"error", title:"Hata!", text:cevap.message
                    , cancelFunc: () => setLoginModal(true), successFunc : () => setLoginModal(true)})
                }
            }).catch((error) => {
                Alert({type:"error", title:"Hata!", text:error.response.message
                , cancelFunc: () => setLoginModal(true), successFunc : () => setLoginModal(true)})
            });
        },
        onChange: values => {
          
        }
    });
    const registerForm = useFormik({
        initialValues: {
            name:"",
            email: '',
            password: '',
            password_confirmation: '',
        },
        validationSchema: register,
        onSubmit: async values => {
            setRegisterModal(false);
            axios.post( process.env.REACT_APP_API_URL + "/register", values )
            .then((response) => {
                let cevap = response.data;
                if(cevap.status == "success"){
                    localStorage.email = cevap.data.email
                    {setActiveModal(true)};
                    //window.location.reload();
                }else{
                    localStorage.remove('token');
                    Alert({type:"error", title:"Hata!", text:cevap.message
                    , cancelFunc: () => setRegisterModal(true), successFunc : () => setRegisterModal(true)})
                }
            }).catch((error) => {
                Swal.fire({type:"error", title:"Hata!", text:error.response.message})
                .then((respo) => {
                    if(respo.isConfirmed || respo.isDismissed){
                        setRegisterModal(true)
                    }
                });
            });
        },
        onChange: values => {
          
        }
    });
    

  // CUSTOM FUNCTIONS
    const selectCity = (e) => {
        let cityValue = e.value;
        setIl(cityValue);
        for(var i = 0; i < il_ilce.length; i++){
            if(cityValue == il_ilce[i].value){
                setIlceler(il_ilce[i].ilceler)
            }
        }
        let ilInp = document.querySelector("[name='il']");
        if(ilInp != undefined && ilInp != null){
        ilInp.value = cityValue;
        }

    }
    const selectDistrict = (e) => {
        let district = e.value;
        setIlce(district);
        let ilInp = document.querySelector("[name='ilce']");
        if(ilInp != undefined && ilInp != null){
        ilInp.value = district;
        }

    }
    const filteradverts = async () => {
        setFilterPopup(false);
        let Url = process.env.REACT_APP_API_URL +  '/adverts';
        Url = `${Url}?title=${Title.current.value}&city=${il}&district=${ilce}&is_award=${isAward}&type=${Type.current.value}`;
        
        await axios.get(Url).then((response) => {
        let cevap = response.data;
        if(cevap.status == "success"){
            dispatch(setAdverts(cevap.data));
        }else{
            alert(cevap.message);
        }
        }).catch((error) => {

        })
    }
    const toggleFilter = () => {
        document.getElementById('side-menu').classList.toggle('hidden');
        document.getElementById('filterArrow').classList.toggle('rotate-180')
    }
    const createPost = () => {
        if(localStorage.token != undefined && localStorage.token != null){
        window.location.replace('/create');
        }else{
        Alert({
            type: "info",
            title: "Giriş yapın",
            text: "Lütfen Önce Giriş yapınız"
        })
        return false;
        }
    }
    const getMe = async () => {
                await axios.get(process.env.REACT_APP_API_URL + '/me', {headers: header})
                .then((response) => {
                    if(response.data.status == 'success'){
                        dispatch(setMe(response.data.data));
                    }
                }).catch((error) => {
                    localStorage.removeItem('token')
                    dispatch(setMe({}))
                    if(error.response != undefined){
                        if(error.response.status == 401){
                            if(window.location.pathname.includes("create") || window.location.pathname.includes("profile")){
                                window.location.replace('/');
                                setLoginModal(true)
                            }
                        }else{
                            console.log(error.response.data.message);
                        }
                    }
            })
    }
    const logout = () => {
            dispatch(setMe({}))
            localStorage.removeItem('token');
            localStorage.removeItem('email');
            window.location.replace("/");
    }
    const toggleMobileMenu = () => {
            document.getElementById('mobile-menu').classList.toggle('hidden')
    }
    const Profile = () => {
            if(localStorage.token != null && localStorage.token != undefined){
                window.location.replace('/profile');
            }else{
                Alert({type:"info", title:"Giriş yapın", text: "Lütfen önce giriş yapın"})
                return false;
            }
    }

    const getFoundedAdverts = async () => {
        await axios.get(process.env.REACT_APP_API_URL + '/founded')
        .then(({ data }) => {
            if(data.status == "success"){
                dispatch(setFounded(data.data));
            }
        });
    }

    const foundedAdverts = () => {
        if(Founded.length > 0){
            return (
                <marquee className="fixed bottom-0 bg-black text-white font-bold" style={{ zIndex:"99999" }}>{
                Founded.map( item => <>&nbsp;  { item?.title } ( {item?.city} ) BULUNDU ! &nbsp; - </>)
                }</marquee>
            )
        }
    }

    const checkStatus = () => {
        if(Me.status != undefined && Me.status == 0){
            return (
            <div className='Alert {type:"error", title:"Hata!", text:text-md font-bold bg-red p-2 text-white flex items-center gap-4 cursor-pointe}r'
                onClick={() => { {setActiveModal(true)} }}>
                <RailwayAlertOutlined style={{ fontWeight:"bold" }} size={29} />
                Lütfen hesabınızı doğrulamak için tıklayın.</div>
            )
        }
    }

    const submitFeedback = async () => {
        setFeedSubmitDisabled(true)
        let description = FeedbackRef.current.value;
        var postData = { "description" : description}
        await axios.post(process.env.REACT_APP_API_URL + '/feedback', postData, { headers: header })
        .then((response) => {
            setFeedbackModal(false);
            if(response.status == "success") {
                FeedSubmit.current.disabled = true;
            }
            response = response.data;
            Alert({
                type: response.status,
                title: "Dikkat",
                text: response?.message ?? response?.data
            })
        }).catch((error) => {
            setFeedbackModal(false);
            if(error.response != undefined){
                Alert({
                    type:"error",
                    title: "Hata",
                    text: error.response.data.message
                })
            }else{
                Alert({
                    type:"error",
                    title: "Hata",
                    text: error
                })
            }
        })
    }

    useEffect(() => {
        getMe();
        getFoundedAdverts();
    }, [])

  return (
    <>
    <div className="w-full flex items-center justify-between p-4 border-b border-black dark:bg-header-dark">
        <div className='logo'>
            <a href='/' className='text items-center justify-center flex flex-row gap-2 cursor-pointer text-xl font-bold'
             style={{ color:fontColor }}>
                <h1 className='hidden md:block text text-xl'>kayipilan.com</h1></a>
        </div>

        <div className='flex flex-row gap-2'>
        
            <Button onClick={() => { 
                Me?.status != undefined && Me?.status == 1 ? createPost() 
                : localStorage.token != undefined && localStorage.token != null ? setActiveModal(true) 
                : setLoginModal(true) }}
                
              type='button' variant='contained'
             color='warning'>
                <span className='hidden text md:block md:text-sm'
                >İlan paylaş &nbsp;</span> <CiBookmarkPlus size={20} className='font-bold' /></Button>
        
            <Button variant='outlined' 
            className='text text-md cursor-pointer font-bold items-center mt-3 gap-2'
             onClick={( () => { setFilterPopup(true) } )}
            style={{ color:fontColor, display: hiddenFilter }}
            ><span 
            className="hidden text md:block text-sm "
            >Filtrele &nbsp;</span> 
            <CiFilter size={20} className='font-bold' /></Button>
        </div>

        <div className='hidden loginAndRegister md:flex items-center justify-around'>
            <ModeSwitch />
            {
                localStorage.token != undefined && localStorage.token != null ? <>
                <Button onClick={() => {Profile()}} style={{ color: fontColor }}>Profil</Button>
                <Button onClick={() => { logout() }} style={{ color: fontColor }}>Çıkış yap</Button>
                </>
                 : <>
                <Button onClick={() => { setLoginModal(true) }} style={{ color: fontColor }}>Giriş yap</Button>
                <Button onClick={() => { setRegisterModal(true) }} style={{ color: fontColor }}>Kayıt ol</Button></>
            }
        <BiSupport className='cursor-pointer ml-2' size={24} color={fontColor} onClick={() => setFeedbackModal(true)} />
        </div>
        <GiHamburgerMenu className='block md:hidden' size={26} onClick={() => { toggleMobileMenu() }} color={fontColor} />

    </div>

    {/* FILTER POPUP */}
    <Popup open={filterPopup} Close={() => setFilterPopup(false)}>
          <h1 className='text text-md font-bold text-black dark:text-white'>İlanları Filtrele</h1>
          <div id="side-menu" className='side-menu lg:p-0 flex flex-col justify-center gap-4'>
              <div className='side-item'>
                  <input placeholder='Ad Soyad' ref={Title} type='text' id='human_name'
                  style={{ color:fontColor }}
                  className='p-2 w-full border border-gray rounded-md bg-transparent' />
              </div>
              <div className='side-item'>
                  <select name='type' style={{ color: fontColor}}
                  className='w-full p-2 border border-black rounded-md' id="type" ref={Type}>
                    <option value={"all"}>Tümü</option>
                    <option value={"human"}>İnsan</option>
                    <option value={"animal"}>Hayvan</option>
                    <option value={"item"}>Eşya</option>
                  </select>
              </div>
              <div className='side-item'>
                  <Select options={il_ilce} id='filter_il'
                      className='w-full bg-transparent' placeholder={il}
                      value={il} defaultValue={il} label={il} styles={{ width: "100%", background: "transparent" }}
                      onChange={(e) => { selectCity(e) }} />
              </div>
              <div className='side-item'>
                <Select options={ilceler} label={ilce} 
                    styles={{ width: "100%", background: "transparent" }}
                    theme={localStorage.theme} background={"transparent"}
                    value={ilce} defaultValue={ilce} id='filter_ilce'
                    onChange={(e) => { selectDistrict(e) }}
                    className='w-full' placeholder={ilce} />
              </div>
              <div className="slide-item">
                <div className="form-group">
                  <FormControlLabel control={<Switch 
                  onChange={() => { setIsAward(!isAward); }} />} 
                 labelPlacement="start" style={{ color : fontColor }} label="Ödüllü" />
                </div>
              </div>
              <div className='slide-item justify-between flex flex-row gap-2'>
                <Button variant='contained' onClick={() => { filteradverts() }} className='slide-item' color='primary'>Filtrele</Button>
                <Button variant='contained' onClick={() => { window.location.reload() }} className='slide-item' color='warning'>Temizle</Button>
              </div>
          </div>
        </Popup>

    {/* LOGIN POPUP */}
    <Popup key={1} open={loginModal} Close={() => { setLoginModal(false) }}>
    <form className='flex flex-col gap-4 items-center justify-center' onSubmit={loginForm.handleSubmit}>
          <div className='form-groyp'>
              <input
                  className='p-2 border rounded-xl'
                  style={{ borderColor: fontColor }}
                  placeholder='Email'
                  autoComplete='off'
                  id="email"
                  name='email'
                  type="email"
                  onChange={loginForm.handleChange}
                  value={loginForm.values.email}
              />
              { loginForm.errors.email && <div className='text text-red'>{ loginForm.errors.email } </div>}
          </div>
          <div className='form-groyp'>
              <input
                  className='p-2 border rounded-xl'
                  id="password"
                  style={{ borderColor: fontColor }}
                  name="password"
                  type="password"
                  placeholder='Şifre'
                  autoComplete='off'
                  onChange={loginForm.handleChange}
                  value={loginForm.values.password}
              />
              { loginForm.errors.password && <div className='text text-red'>{ loginForm.errors.password } </div>}
          </div>
          <button type="submit" style={{ color: fontColor}}>Giriş yap</button>
      </form>
    </Popup>

    {/* REGISTER POPUP */}
    <Popup key={2} open={registerModal} Close={() => { setRegisterModal(false) }}>
    <form className='flex flex-col gap-4 items-center justify-center' autoComplete='off' onSubmit={registerForm.handleSubmit}>
      <div className='form-group'>
          <input
              className='p-2 border rounded-xl'
              style={{ borderColor: fontColor }}
              id="name"
              name="name"
              placeholder='Ad Soyad'
              autoComplete='off'
              type="text"
              onChange={registerForm.handleChange}
              value={registerForm.values.name}
          />
            { registerForm.errors.name && <div className='text text-red'>{ registerForm.errors.name } </div>}
      </div>
      <div className='form-groyp'>
          <input
              className='p-2 border rounded-xl'
              style={{ borderColor: fontColor }}
              id="email"
              name="email"
              placeholder='Email'
              autoComplete='off'
              type="email"
              onChange={registerForm.handleChange}
              value={registerForm.values.email}
          />
              { registerForm.errors.email && <div className='text text-red'>{ registerForm.errors.email } </div>}
      </div>
      <div className='form-groyp'>
          <input
              className='p-2 border rounded-xl'
              id="password"
              style={{ borderColor: fontColor }}
              name="password"
              type="password"
              placeholder='Şifre'
              autoComplete='off'
              onChange={registerForm.handleChange}
              value={registerForm.values.password}
          />
              { registerForm.errors.password && <div className='text text-red'>{ registerForm.errors.password } </div>}
      </div>
      <div className='form-groyp'>
          <input
              className='p-2 border rounded-xl'
              id="password_confirmation"
              style={{ borderColor: fontColor }}
              name="password_confirmation"
              type="password"
              placeholder='Şifre Tekrar'
              autoComplete='off'
              onChange={registerForm.handleChange}
              value={registerForm.values.password_confirmation}
          />
            { registerForm.errors.password_confirmation && <div className='text text-red'>{ registerForm.errors.password_confirmation } </div>}
      </div>
      <span className='text text-light italic'
       style={{ color: fontColor }}>Dikkat! Kayıt olurken <a href='/kvkk'>KVKK</a> metnini kabul etmiş olursunuz.</span>
      <button type="submit" style={{ color: fontColor}}>Kayıt ol</button>
    </form>
  </Popup>

    {/* ACTIVATE POPUP */}
  <Popup key={3} open={activeModal} Close={() => { setActiveModal(false) }}>
    <h1 className='text text-md font-bold' style={{ color:"black" }}>Doğrulama Kodu</h1>
    <span className='text text-sm' style={{ color:"black" }}>Mail adresinie gelen kodu aşağıya giriniz.</span>
    <form className='flex flex-col gap-4 mt-4 items-center justify-center' onSubmit={activateForm.handleSubmit}>
          <div className='form-groyp'>
              <input
                  className='p-2 border border-black rounded-xl'
                  style={{ borderColor: fontColor, borderColor:"black" }}
                  placeholder='Doğrulama Kodu'
                  autoComplete='off'
                  id="code" maxLength={6}
                  name='code'
                  type="number"
                  onChange={activateForm.handleChange}
                  value={activateForm.values.code}
              />
              { activateForm.errors.code && <div className='text text-red'>{ activateForm.errors.code } </div>}
          </div>
          <div className='flex items-center justify-between w-full'>
            <Button variant=''>Kod Gönder</Button>
            <Button variant='' type="submit">Doğrula</Button>
          </div>
      </form>
    </Popup>

    {/* DELETE POPUP */}
    <Popup key={4} open={deleteModal} Close={() => { setDeleteModal(false) }}>
        İlanı silmek istediğinize emin misiniz
    </Popup>

     {/* FEEDBACK POPUP */}
     <Popup key={5} open={feedbackModal} Close={() => { setFeedbackModal(false) }}>
        <span className='text text-md font-bold' style={{color:fontColor}}>Lütfen bize mesajınızı iletin</span>
        <textarea ref={FeedbackRef} style={{ border: "1px solid " + fontColor, color: fontColor }}
         onKeyUp={(e) => { console.log("ok"); if(e.target.value.length >= 3) { setFeedSubmitDisabled(false);} }}
         className='w-full p-2 h-20 text-black dark:text-black mt-2' placeholder='Talebiniz'></textarea>
        <div className="flex flex-row justify-between gap-2">
            <Button className='w-full' variant='contained' onClick={() => setFeedbackModal(false)}>Kapat</Button>
            <Button className='w-full' ref={FeedSubmit} variant='contained' disabled={feedSubmitDisabled} color='success' onClick={() => submitFeedback() }
            style={{ color: fontColor}}>Gönder</Button>
        </div>
    </Popup>
    
    { checkStatus() }

    <div id='mobile-menu' className='hidden mobile-menu p-2'>
        <ul className='flex flex-col gap-4 items-start'>
            {
                Me.status != undefined && Me.status != null ?
                <> 
                <li className='text-black dark:text-white hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black w-full p-2'>
                    <ModeSwitch />
                </li>
                
                <li className='text-black dark:text-white hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black w-full p-2'
                ><a href='/profile'>Profil</a></li>
                <li onClick={() => {logout()}} className='text-black dark:text-white hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black w-full p-2
                '><a >Çıkış</a></li>
                </>
                : 
                <>
                <li onClick={() => { setLoginModal(true) }} className='text-black dark:text-white hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black w-full p-2'
                ><a>Giriş yap</a></li>
                <li onClick={() => { setRegisterModal(true) }} className='text-black dark:text-white hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black w-full p-2'>
                    <a>Kayıt ol</a></li>
                </>
            }
            
        </ul>
    </div>
    {
        foundedAdverts()
    }

  </>
  )
}

export default Header;