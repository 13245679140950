import { Button } from '@mui/material';
import React, { useState, useRef } from 'react'
import axios from 'axios';
import { Alert } from '../../Helpers/helpers';
import { FaQuoteRight,FaExclamationTriangle,FaPaperPlane } from "react-icons/fa";

const CommentsComp = ({ data, advertID, refreshComments }) => {
    const [ btnText, setBtnText ] = useState("Cevapları Göster")

    const InputRef = useRef();
    const AnswerRef = useRef();

    const sendComment = async (comment_id=0, answer="") => {
        let comment = answer != "" ? answer : InputRef?.current?.value;
        let data = {
            advert_id : advertID,
            comment : comment
        }
        if(comment_id != 0) data["parent_comment"] = comment_id;
        let url = 'add-comment';
        await axios.post(process.env.REACT_APP_API_URL + "/" + url, data, {
            headers: { "Authorization" : "Bearer " + localStorage.token }
        }).then((response) => {
            if(response.data?.status == "success"){
                refreshComments(true);
            }else{
                Alert({
                    type: "error",
                    title: "Hata !",
                    text: response.data?.message
                });   
            }
        }).catch((error) => {
            Alert({
                type: "error",
                title: "Hata !",
                text: error.response.data?.message
            });
        })
        let inputs = document.getElementsByClassName('inputs');
        for(var i = 0; i < inputs.length; i++){ inputs[i].value = "" }
    }

    const complaintComment = async (comment_id=0) => {
        if(comment_id == 0) {
            Alert({
                type: "error",
                title: "Hata",
                text: "Yorum şikayet edilemedi, hata tekrarlanıyorsa sağ üst kısımdan lütfen bize bildirin"
            });
        }
        let url = '/complaint-comment/' + comment_id;
        let data = { comment_id : comment_id }
        await axios.post(process.env.REACT_APP_API_URL + url, data, {
            headers: { "Authorization" : "Bearer " + localStorage.token }
        }).then((response) => {
            if(response.data?.status == "success"){
                Alert({
                    type: "success",
                    title: "Başarılı",
                    text: "Yorum başarıyla şikayet edildi."
                });
            }else{
                Alert({
                    type: "error",
                    title: "Hata !",
                    text: response.data?.message
                });   
            }
        }).catch((error) => {
            Alert({
                type: "error",
                title: "Hata !",
                text: error.response.data?.message
            });
        })
        let inputs = document.getElementsByClassName('inputs');
        for(var i = 0; i < inputs.length; i++){ inputs[i].value = "" }
    }

    const showSubComments = (comment_id) => {
        let CommentRef = document.getElementById(comment_id);
        setBtnText(CommentRef?.classList?.contains('hidden') ? "Cevapları Göster" : 'Cevapları Gizle')
        if(CommentRef != undefined) CommentRef.classList.toggle('hidden')
    }

    const writeAnswer = (comment_id) => {
        let CommentRef = document.getElementById(comment_id);
        if(CommentRef != undefined) CommentRef.classList.toggle('hidden')
    }
  return (
    <div className='comments h-20vh w-full flex flex-col gap-1'>
        <div className='flex flex-row justify-between items-center yorum-alani'>
            <div className='w-full'>
                <input type='text' placeholder='Yorum yap' maxLength={400} ref={InputRef} onKeyDown={(e) => e.key == "Enter" ? sendComment() : <></> }
                className='inputs bg-transparent !border border-black dark:border-white w-full rounded-lg p-1'/>
            </div>
            <div>
            <Button onClick={() => sendComment()}> <FaPaperPlane color='white' /> </Button>
            </div>
        </div>
        {
            data?.map( comment => 
                <div className='comment flex flex-col p-2 w-full'>
                    <div className='border-b border-black dark:border-white'>
                        
                        <div className='user-pcs text flex flex-row gap-2 items-center text-sm text-black dark:text-white font-bold'>
                                { comment?.user?.name }
                                <button className='bg-transparent border-none ' onClick={() => { complaintComment(comment?.id) }}
                                    ><FaExclamationTriangle className='text-danger cursor-pointer' size={15} />
                                </button>
                        </div>
                        <div className='flex flex-row justify-between items-center'> 
                            <div className='msg text text-sm text-black dark:text-white font-light'>{ comment?.comment } </div>
                            <div className='complaint'>
                                <button className='bg-transparent border-none' onClick={() => writeAnswer(comment?.id + '_answer')}
                                ><FaQuoteRight className='text-black dark:text-white cursor-pointer' size={15} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id={ comment?.id + '_answer'} className='hidden flex flex-row justify-between items-center mt-2 cevap-alani'>
                        <div className='w-full'>
                            <input type='text' placeholder='Yorum yap' maxLength={400} id={ comment?.id + '_answer_inp'}
                            onKeyDown={(e) => e.key == "Enter" ? sendComment(comment?.id, e.target.value) : <></> }
                            className='inputs bg-transparent !border border-black dark:border-white w-full rounded-lg p-1'/>
                        </div>
                        <div>
                        <Button onClick={() => sendComment(comment?.id, document.getElementById(comment?.id + '_answer_inp')?.value)} ><FaPaperPlane color='white' /> </Button>
                        </div>
                    </div>
                    <div id={ comment?.id } className='sub-comments hidden'>
                        {
                            comment?.sub_comments?.map( item => 
                                <div className='border-b ml-2 p-2 border-black dark:border-white'>
                                    <div className='user-pcs text text-sm text-black dark:text-white font-bold'>{ item.user?.name }</div>
                                    <div className='flex flex-row justify-between items-center'> 
                                        <div className='msg text text-sm text-black dark:text-white font-light'>{ item?.comment } </div>
                                        <div className='complaint'>
                                            <button className='bg-transparent border-none '
                                            ><FaExclamationTriangle onClick={() => { complaintComment(item?.id) }} className='text-danger cursor-pointer' size={15} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                        )
                    }
                    </div>
                    {
                        comment?.sub_comments?.length > 0 ? 
                        <button onClick={(e) => {showSubComments(comment?.id); e.target.text = "Gizle"} } id={comment?.id + "_btn"}
                        className='show-answers text-sm mt-2 text-black dark:text-white font-bold'
                        >{ comment?.sub_comments?.length } Cevap </button> : <></>
                    }
                    
                </div>
                )
        }
    </div>
  )
}

export default CommentsComp