import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    Advert:{},
    CloseAdverts:[],
    Comments : []
}

const PostDetailSlice = createSlice({
    name: "post_detail",
    initialState,
    reducers:{
        setAdvert(state,action){
            state.Advert = action.payload;
        },
        setCloseAdverts(state,action){
            state.CloseAdverts = action.payload;
        },
        setComments(state,action){
            state.Comments = action.payload;
        }
    }
})

export const { setAdvert, setCloseAdverts, setComments } = PostDetailSlice.actions
export default PostDetailSlice.reducer;